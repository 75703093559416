:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(from 180deg at 50% 50%,
      #16abff33 0deg,
      #0885ff33 55deg,
      #54d6ff33 120deg,
      #0071ff33 160deg,
      transparent 360deg);
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080,
      #00000040,
      #00000030,
      #00000020,
      #00000010,
      #00000010,
      #00000080);

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #CBD5E0;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  transition: all 0.2s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #A0AEC0;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

/* @font-face {
  font-family: 'MarckScript';
  src: url('../public/fonts/MarckScript-Regular.ttf') format('ttf');
  font-weight: 400;
} */
@import url('https://fonts.googleapis.com/css2?family=Marck+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../public/fonts/EuclidCircularB-Light.woff2') format('woff2');
  font-weight: 100;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../public/fonts/EuclidCircularB-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../public/fonts/EuclidCircularB-Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../public/fonts/EuclidCircularB-SemiBold.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../public/fonts/EuclidCircularB-Bold.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../public/fonts/EuclidCircularB-LightItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../public/fonts/EuclidCircularB-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../public/fonts/EuclidCircularB-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../public/fonts/EuclidCircularB-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../public/fonts/EuclidCircularB-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: 'metric';
  src: url('../public/fonts/metric-thin.woff2') format('woff2');
  font-weight: 100;
}

@font-face {
  font-family: 'metric';
  src: url('../public/fonts/metric-light.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'metric';
  src: url('../public/fonts/metric-regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'metric';
  src: url('../public/fonts/metric-medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'metric';
  src: url('../public/fonts/metric-semibold.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'metric';
  src: url('../public/fonts/metric-bold.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'metric';
  src: url('../public/fonts/metric-black.woff2') format('woff2');
  font-weight: 900;
}

.aiprise-button {
  margin-top: 16px;
  margin-left: 16px;
  /* Other CSS stuff... */
}

#nprogress .bar {
  background: rgb(15, 15, 44);
}


@tailwind base;
@tailwind components;
@tailwind utilities;


/*Radix*/
.PopoverContent {
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.PopoverContent[data-state='open'][data-side='top'] {
  animation-name: slideDownAndFade;
}

.PopoverContent[data-state='open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}

.PopoverContent[data-state='open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}

.PopoverContent[data-state='open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

.SelectContent {
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.SelectContent[data-state='open'] {
  animation-name: fade;
}

.DialogOverlay {
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent {
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.dashboard-bg {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(15 23 42 / 0.04)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
}

.login-background {
  background-color: #ffffff;
  /* opacity: 0.4; */
/*   background-image: radial-gradient(#662180 0.5px, #ffffff 0.5px); */
  background-size: 10px 10px;

}